.drag-drop-upload {
    position: relative;
    border: 5px dashed rgba(198, 198, 198, 0.65);
/*   border-radius: 4px; */
    padding: 20px;
    text-align: center;
    transition: border-color 0.3s ease;
  }
  
  .drag-drop-upload.dragging {
    border-color: #aaa;
  }
  
  .drag-drop-upload:hover {
    cursor: pointer;
  }

  /* .fileSelected{
    border: none !important;
  } */
  
  .draggable::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    z-index: 1;
    transition: opacity 0.3s ease;
  }
  
  .drag-drop-upload:hover::before {
    opacity: 1;
  }
  
  .drag-drop-upload p {
    margin: 0;
    z-index: 2;
  }
  
  .drag-drop-upload__instructions {
    color: #666;
    font-size: 12px;
    margin-top: 5px;
  }

  .rs-progress-line{
    padding: 0 !important;
  }

  .rs-progress-line-outer{
    border-radius: 0 !important;
  }

  .icons {
    color: #95afc0;
    opacity: 0.55;
  }

